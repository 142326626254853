<template>
  <v-container>
    <div>
      <template v-if="$vuetify.breakpoint.xsOnly">
        <v-container>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="4"
            >
              <v-autocomplete
                v-model="banka"
                label="Banka"
                style=" width: 100%;"
                :items="this.bankaData"
                hide-details
                rounded
                class="rounded-lg mr-3"
                filled
                background-color="chip"
                item-text="text"
                item-value="value"
                @change="bankChange(banka)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <hr />
          <v-divider />
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="8"
            >
              <v-text-field
                v-model="searchModel"
                class="rounded-0"
                placeholder="İsteğe Bağlı"
                persistent-placeholder
                rounded
                filled
                clearable
                hide-details
                :prepend-inner-icon="icons.mdiMagnify"
                @change="searchChange(searchModel)"
              ></v-text-field>
            </v-col>
          </v-row>
          </v-toolbar>
        </v-container>
      </template>

      <template v-if="!$vuetify.breakpoint.xsOnly">
        <v-container>
          <v-toolbar
            color="transparent"
            :height="$vuetify.breakpoint.smAndDown ? 120 : 87"
            class="toolbar-spacing"
          >
            <v-toolbar-title>
              <div
                class="d-flex align-center"
                :class="$vuetify.breakpoint.smAndDown ? 'pb-3' : null"
              >
                <v-icon
                  left
                >
                  {{ icons.mdiBank }}
                </v-icon>
                <span class="text-body-2 text-md-h6 font-weight-bold">
                  Banka Hesapları
                </span>
              </div>
            </v-toolbar-title>
          </v-toolbar>


          <v-row no-gutters>
            <v-col
              cols="12"
              sm="4"
            >
              <v-autocomplete
                v-model="banka"
                label="Banka"
                :items="this.bankaData"
                hide-details
                rounded
                class="rounded-lg mr-3"
                filled
                background-color="chip"
                item-text="text"
                item-value="value"
                @change="bankChange(banka)"
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              sm="8"
            >
              <v-text-field
                v-model="searchModel"
                class="rounded-0"
                placeholder="İsteğe Bağlı"
                persistent-placeholder
                rounded
                filled
                clearable
                hide-details
                :prepend-inner-icon="icons.mdiMagnify"
                @change="searchChange(searchModel)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <v-row>
        <!-- Stumptown Roasters. -->
        <!--    <v-col v-for="(item, index) in this.accountsData" :key="index" cols="12" md="4">
          <v-card>
            <v-container>
              <div class="d-flex flex-column-reverse flex-md-row">
                <div>
                  <v-card-title> {{ item.bank_title }} </v-card-title>
                  <v-card-text class="d-flex align-center flex-wrap body-1">
                    <span class="text-sm">{{ item.hesapTuruTxt }}</span>
                  </v-card-text>
                  <v-card-text class="d-flex align-center flex-wrap body-1">
                    <span class="text-sm">{{ item.hesapNo }}</span>
                  </v-card-text>
                  <v-card-text>
                    <span style="color: green" class="text-xl">{{
                      item.bakiye | currency({ symbol: item.dovizKodu })
                    }}</span>
                  </v-card-text>

                  <v-card-actions class="dense">
                    <v-icon>
                      {{ icons.mdiTextBoxMultiple }}
                    </v-icon>
                    <v-btn dark text color="primary"> İNCELE </v-btn>
                  </v-card-actions>
                </div>
                <div class="pa-4">
                  <v-img
                    :src="item.bank_logo"
                    :class="$vuetify.breakpoint.mdAndUp ? 'rounded-lg' : 'rounded-0'"
                    height="30%"
                    :max-width="$vuetify.breakpoint.mdAndUp ? '160' : '100%'"
                  ></v-img>
                </div>
              </div>
            </v-container>
          </v-card>
        </v-col> -->

        <v-col
          v-for="(item, index) in this.accountsData"
          :key="index"
          md="3"
          sm="6"
          cols="12"
          class="align-self-start pa-1"
        >
          <v-container  fluid class="pa-3 ma-0" >
            <v-card class="pa-2">
              <v-img
                :src="item.bank_logo"
                :class="[
                  $vuetify.breakpoint.mdAndUp ? 'rounded-lg' : 'rounded-lg',
                  'full-width-image'
                ]"
                :height="height"
                max-width="100%"

              >
              </v-img>
              <v-card-title class="card-title"> {{ item.bank_title }} </v-card-title>
              <v-card-text v-if="item.hesapTuruTxt !== ''">
                {{ item.hesapTuruTxt }}
              </v-card-text>
              <v-card-text v-if="item.hesapTuruTxt === ''">
                YOK
              </v-card-text>

              <v-card-actions class="dense">
                <v-icon>
                  {{ icons.mdiTextBoxMultiple }}
                </v-icon>
                <v-btn
                  color="primary"
                  text
                  @click="listBank(item)"
                >
                  İNCELE
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  @click="isCardDetailsVisible = !isCardDetailsVisible"
                >
                  <v-icon>{{ isCardDetailsVisible ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
                </v-btn>
              </v-card-actions>
              <v-expand-transition>
                <div v-show="isCardDetailsVisible">
                  <v-divider></v-divider>

                  <v-card-text class="d-flex align-center flex-wrap body-1">
                    <span class="text-sm font-weight-semibold mr-2">Hesap No: </span>
                    <span class="text-xs">{{ item.hesapNo }}</span>
                  </v-card-text>



                  <v-card-text class="d-flex align-center flex-wrap body-1">
                    <span class="text-sm font-weight-semibold mr-2">Şube Adı:</span>
                    <span class="text-xs">{{ item.subeAdi }}</span>
                  </v-card-text>

                  <v-card-text>
                    <span
                      style="color: green"
                      class="text-xl"
                    >
                      {{ item.bakiye | currency({ symbol: item.dovizKodu }) }}</span>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-container>
        </v-col>
      </v-row>

      <v-alert
      v-if="loadingSelectText"
      :type="loadingSelectStatus"
      border="left"
      class="alert-center"
    >
      {{ loadingSelectText }}
    </v-alert>
    </div>

      <!-- Yukarı Çık Butonu -->
      <v-btn
      v-if="$vuetify.breakpoint.xsOnly"
      v-show="showScrollToTop"
      class="scroll-to-top"
      @click="scrollToTop"
      fab
      fixed
      bottom
      right
      color="primary"
      dark
    >
      <v-icon> {{ icons.mdiChevronUp }} </v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import router from '@/router'
import store from '@/store'
import axios from '@axios'
import useVuetify from '@core/utils/vuetify'
import {
mdiAccountOutline,
mdiBank,
mdiCartPlus,
mdiChevronDown,
mdiChevronUp,
mdiHelpCircleOutline,
mdiLockOpenOutline,
mdiMagnify,
mdiShareVariantOutline,
mdiStarOutline,
mdiTextBoxMultiple,
mdiTrendingUp,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'
export default {
  data() {
    return {
      showScrollToTop: false, // Butonun görünürlüğünü kontrol eder
      accountsData: [],
      bankaData: [],
      search: '',
      searchModel: '',
      loadingSelectText: '',
      loadingSelectStatus: null,
    }
  },

  created() {
    this.loadData()
    this.fetchBankalar()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    loadData(search = '0', typeId) {
      debugger

      let company
      if (store.state.companyCustomer) {
        company = store.state.companyCustomer
      }

      //const loadingSelectStatus = ref(null)
      //const loadingSelectText = ref('')
      const params = new URLSearchParams()
      params.append('method', 'getUserBankAccounts')
      params.append('web_api', 1)
      params.append('company', company)
      if (this.banka !== '') {
        params.append('banka_kodu', this.banka)
      } else if (search !== '0' && search !== null && typeId === 1) {
        params.append('banka_kodu', search)
      }

      if (this.searchModel !== '') {
        params.append('search', this.searchModel)
      } else if (search !== '0' && search !== '' && search !== null && typeId === 2) {
        params.append('search', search)
      }
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            this.loadingSelectText  = response.data.msg
            this.loadingSelectStatus = 'error'

            if (response.data.error_code === 99) {
            Vue.swal({
              title: 'Oturum Sonlandı!',
              html: 'Geçersiz Token',
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            router.push('/login')

            return
          }
            return
          }

debugger
if (!response.data.response || response.data.response.length === 0) {
  this.loadingSelectText  = 'Kayıt Bulunamadı';
  this.loadingSelectStatus = 'info';
            this.accountsData = []; // Ya da uygun bir varsayılan değer
          } else {
            this.accountsData = response.data.response;
            this.loadingSelectText = ''; // Mesajı temizle
            this.loadingSelectStatus= null; // Durumu temizle
          }
        })
        .catch(e => {
          this.loadingSelectText = 'Bir hata oldu tekrar deneyin'
          this.loadingSelectStatus= 'error'
        })
    },

    fetchBankalar() {
      const params = new URLSearchParams()
      params.append('method', 'getBankList')
      params.append('web_api', 1)
      axios.post('', params).then(response => {
        debugger
        if (response.error_code === 99) {
          Vue.swal({
            title: 'Oturum Sonlandı!',
            html: 'Geçersiz Token',
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
          router.push('/login')

          return
        }
        if (response.error === true) {
          return
        }

        this.bankaData.push({
          value: 0,
          text: 'Hepsi',
        })
        for (const [key, value] of Object.entries(response.data.response.bankalar)) {
          this.bankaData.push({
            value: value.banka,
            text: value.banka,
          })
        }
      })
    },

    bankChange(newValue) {
      debugger
      this.banka = ''

      // Otomatik tamamlamada seçim değiştiğinde çalışacak işlev
      console.log('Yeni seçilen öğe:', newValue)
      this.loadData(newValue, 1)
      this.banka = newValue
    },
    searchChange(newValue) {
      debugger
      this.searchModel = ''

      // Otomatik tamamlamada seçim değiştiğinde çalışacak işlev
      console.log('Yeni seçilen öğe:', newValue)
      this.loadData(newValue, 2)
      this.searchModel = newValue
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    handleScroll() {
      this.showScrollToTop = window.scrollY > 200 // Sayfa kaydırma mesafesine göre butonu gösterir
    },
  },

  setup() {
    onMounted(() => {
      // fetchBankalarSetup()
      // getUserBankAccounts()
    })

    const bankalarItems = []
    const banka = ref('')

    const fetchBankalarSetup = () => {
      const params = new URLSearchParams()
      params.append('method', 'getBankList')
      params.append('web_api', 1)
      axios.post('', params).then(response => {
        if (response.error_code === 99) {
          Vue.swal({
            title: 'Oturum Sonlandı!',
            html: 'Geçersiz Token',
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
          router.push('/login')

          return
        }
        if (response.error === true) {
          return
        }

        for (const [key, value] of Object.entries(response.data.bankalar)) {
          bankalarItems.push({
            value: value.banka,
            text: value.banka,
          })
        }
      })
    }
    const UserBankItems = []
    //const loadingSelectStatus = ref(null)
    //const loadingSelectText = ref(null)

    const getUserBankAccounts = () => {
      const params = new URLSearchParams()
      params.append('method', 'getUserBankAccounts')
      params.append('web_api', 1)
      axios
        .post('', params)
        .then(response => {
          debugger
          if (response.error_code === 99) {
            Vue.swal({
              title: 'Oturum Sonlandı!',
              html: 'Geçersiz Token',
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            router.push('/login')

            return
          }
          if (response.data.error) {
            this.loadingSelectText  = response.data.msg
            this.loadingSelectStatus = 'error'

            return
          }
          this.accountsData = response.data.response
        })
        .catch(e => {
          this.loadingSelectText  = 'Bir hata oldu tekrar deneyin'
          this.loadingSelectStatus = 'error'
        })
    }

    const { rootThemeClasses } = useVuetify()
    const isCardDetailsVisible = true
    const rating = ref(5)

    const listBank = item => {
      console.log(item.id)
      router.push({ path: `/accounts/transactions/list/${item.id}` })
    }

    return {
      fetchBankalarSetup,
      bankalarItems,
      banka,

      //loadingSelectText,
      //loadingSelectStatus,
      UserBankItems,
      getUserBankAccounts,

      rootThemeClasses,
      isCardDetailsVisible,
      rating,
      listBank,

      // icons
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
        mdiTextBoxMultiple,
        mdiMagnify,
        mdiBank,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
.container-text {
  display: flex;
  flex-wrap: wrap; /* Metin boyutları değiştikçe alt satıra geçmesini sağlar */
}
.toolbar-spacing {
  margin-bottom: 20px; /* Adjust as needed */
}

.container {
  width: 100%;
  padding: 8px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 600px) {
  .container {

    width: calc(100% - 0px); /* Sağ ve sol boşlukları azaltır */
    padding: 3px;
    margin: 30px auto 0; /* Üstten boşluk ekler, sağ ve sol boşlukları azaltır */

  }
}

.full-width-image {
  object-fit: contain; /* Görselin kutunun içinde orantılı olarak görünmesini sağlar */
  max-height: 150px; /* Maksimum yüksekliği sınırladık */
  width: auto; /* Genişliği otomatik ayarla, orantılı kalmasını sağlar */

  //width: 100%; /* Resmin genişliği konteynerin tam genişliği kadar olur */
  height: auto; /* Yüksekliği orantılı olarak ayarlar */
  //object-fit: cover; /* Resmin konteyneri tamamen kaplamasını sağlar */
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2000; /* Sayfadaki diğer elemanların üstünde görünecek şekilde z-index ayarı yapabilirsiniz */
}

.bank-logo {
  object-fit: contain; /* Görselin kutunun içinde orantılı olarak görünmesini sağlar */
  max-width: 120px; /* Maksimum genişlik */
  height: auto; /* Yüksekliği otomatik ayarla */
}
.v-alert {
  /* Alert için özel stil ayarları buraya gelebilir */
  font-size: 1rem;
}
.alert-top-right {
  position: fixed; /* Konumu sabitlemek için fixed kullanıyoruz */
  top: 16px; /* Üstten uzaklığı ayarlıyoruz */
  right: 16px; /* Sağdan uzaklığı ayarlıyoruz */
  z-index: 2000; /* Diğer içeriklerin üstünde görünmesini sağlıyoruz */
  max-width: 300px; /* Genişliği sınırlıyoruz, ihtiyaca göre ayarlayın */
}
.alert-center {
  position: fixed; /* Konumu sabitlemek için fixed kullanıyoruz */
  top: 50%; /* Ekranın ortasına getirmek için */
  left: 50%; /* Ekranın ortasına getirmek için */
  transform: translate(-50%, -50%); /* Tam olarak ortalamak için */
  z-index: 2000; /* Diğer içeriklerin üstünde görünmesini sağlıyoruz */
  max-width: 300px; /* Genişliği sınırlıyoruz, ihtiyaca göre ayarlayın */
}

.card-title {
  font-size: 0.9rem; /* Font boyutunu küçültür */
  line-height: 1.2; /* Satır yüksekliğini ayarlar */
  overflow: hidden; /* Taşan metni gizler */
  word-break: break-word; /* Metnin taşmasını önler ve satır içi taşmaları yönetir */
  display: -webkit-box; /* Flexbox düzeni sağlar */
  -webkit-box-orient: vertical; /* Metni dikey olarak hizalar */
  -webkit-line-clamp: 2; /* İki satıra sığacak şekilde keser */
}

@media (max-width: 600px) {
  .full-width-image {
    height: auto; /* Yüksekliği otomatik yaparak oranı korur */
  }
}
</style>
